<template>
  <div class="shortcut">
    <el-col :xs="24" :md="24" :lg="19" :xl="18">
      <div class="left">
        <a class="logo">shblog</a>
      </div>
      <div class="right" style="position: relative;">
        <div v-if="username">
          <span class="iconfont icon-home" @click="homeIcon"></span>
          <span class="iconfont icon-Write" @click="writeIcon"></span>
          <span class="iconfont icon-richeng_schedule" @click="drawer = true"></span>
          <span class="iconfont icon-notification" ></span>
          <span class="iconfont icon-person" @mouseenter="showBox" @mouseleave="hideBox"></span>
          <div class="opaticybox" v-show="isShowBox" @mouseenter.stop="showBox" @mouseleave.stop="hideBox"></div>
          <div class="hoverbox" v-show="isShowBox" @mouseenter.stop="showBox" @mouseleave.stop="hideBox">
            <div class="username" style="text-align: center; margin-bottom: 10px;">
              {{ username }},你好
            </div>
            <div class="userinfo capa" style="text-align: center; margin-bottom: 10px;" @click="userinfoBtn">
              个人中心
            </div>
            <div class="capa" style="text-align: center; margin-bottom: 10px;" @click="privacyBtn">
              <span>我的随笔记/日记</span>
            </div>
            <div class="logout">
              <a><span class="iconfont icon-signout" @click="logout">退出登录</span></a>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="login" @click="loginBtn">登录/注册</span>
        </div>
        
      </div>
    </el-col>

    <el-drawer
  title="我的任务表"
  :visible.sync="drawer"
  :direction="direction"
  :before-close="handleClose">
  <span style="margin-left: 20px;">等待后续开发使用</span>
</el-drawer>
    
  </div>
</template>

<script>
import  { getUsername } from '@/utils/auth'
import { mapActions } from 'vuex';

export default {
  name: 'ShortCut',
  data(){
    return{
      username: this.$store.name || getUsername(),
      isShowBox: false,

      drawer: false,
      direction: 'rtl',
    }
  },

  


  methods:{

      handleClose(done) {
        done()
        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     done();
        //   })
        //   .catch(_ => {});
      },


    ...mapActions(['user/logout']),
    showBox(){
      this.isShowBox = true
    },
    hideBox(){
      this.isShowBox = false
      
    },
    loginBtn(){
      this.$router.push('/login')
    },
    logout(){
      this['user/logout']().then(res => {this.$router.push('/login')}).catch(err => {console.log(err)})
    
    },
    homeIcon(){
      this.$router.push('/')
    },
    writeIcon(){
      this.$router.push('/main/home/paper')
    },
    userinfoBtn(){
      this.$router.push('/userinfo')
    },
    privacyBtn(){
      this.$router.push('/privacy')
    }
  }

}
</script>

<style scoped>



.capa:hover{
  cursor: pointer;
}

.opaticybox{
  position: absolute;
  width: 250px;
  height: 30px;
  opacity: 0;
  top: 30px;
  right: -100px;
}

.hoverbox{
  position: absolute;
  width: 250px;
  height: 200px;
  background-color: white;
  border: 2px solid rgba(127, 127, 127, .3);
  opacity: 1;
  top: 40px;
  right: -100px;
  padding: 10px 20px;

  border-radius: 15px;
  box-shadow: 0 0 7px 1px #c5c5c5;

  z-index: 9999;
}

.icon-home,
.icon-notification,
.icon-person,
.icon-richeng_schedule,
.icon-Write {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 20px !important;
  margin-left: 25px;
  color: rgba(127, 127, 127, 1);
  transition: all .2s;
}

.icon-home:hover,
.icon-notification:hover,
.icon-person:hover,
.icon-richeng_schedule:hover,
.icon-Write:hover {
  cursor: pointer;
  color: rgba(0, 255, 255, 1);

}

.logout{
  text-align: center;
}

.logout:hover{
  cursor: pointer;
}

.icon-signout{
  color: rgba(52, 52, 52, .7)
}

.shortcut{
  display: flex;
  justify-content: center;
  height: 30px;
  margin-bottom: 5px;
  box-shadow: 0px -5px 20px rgba(127, 127, 127, 1);
  /* box-shadow: rgb(197, 197, 197) 0px 0px 7px 1px; */
}

.left{
  float: left;
}

.right{
  float:right
}

.logo{
  line-height: 30px;
}

.login{
  display: inline-block;
  width: 90px;
  line-height: 30px;
  /* background-color: rgba(0, 255, 255, .3); */
  background-color: rgba(166, 214, 218, .5);
  text-align: center;
  border-radius: 15px;
  transition: all .2s;
}


.login:hover{
  cursor: pointer;
  background-color: rgba(0, 255, 255, 1);
}




</style>