import { render, staticRenderFns } from "./ShortCut.vue?vue&type=template&id=2c6992ce&scoped=true"
import script from "./ShortCut.vue?vue&type=script&lang=js"
export * from "./ShortCut.vue?vue&type=script&lang=js"
import style0 from "./ShortCut.vue?vue&type=style&index=0&id=2c6992ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6992ce",
  null
  
)

export default component.exports