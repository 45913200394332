import Cookies from "js-cookie";

const TokenKey = 'shblog_token'
const RefreshTokenKey = 'shblog_refresh_token'

const UsernameKey = 'username'

// 在cookie中放置username
export function getUsername(){
    return Cookies.get(UsernameKey)
}

export function setUsername(name){
    return Cookies.set(UsernameKey, name)
}

export function removeUsername(){
    return Cookies.remove(UsernameKey)
}

// 令牌
export function getToken(){
    return Cookies.get(TokenKey)
}

export function setToken(token){
    return Cookies.set(TokenKey, token)
}

export function removeToken(){
    return Cookies.remove(TokenKey)
}

// 刷新令牌
export function getRefreshToken(){
    return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token){
    return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken(){
    return Cookies.remove(RefreshTokenKey)
}